import { createContext, useContext, useEffect, useState, useReducer } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth';
import { auth  } from "../firebase";
import { collection, getDocs, addDoc } from "firebase/firestore";
import {db} from '../firebase'
import { cartReducer } from "../redux/cartReducer";
const userCollectionRef = collection(db, "newusers")


const userAuthContext = createContext();

export default function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState("");
  const labtestRef = collection(db, "Garments")

  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }
  function logIn(email, password) {
    return (
      signInWithEmailAndPassword(auth, email, password,)
    )
  }

  function logOut() {
    return signOut(auth)
  }

  function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider()
    return signInWithPopup(auth,googleAuthProvider)
  }

  function setUpRecaptcha(number) {
    const recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {},
      auth
    );
    recaptchaVerifier.render();
    return signInWithPhoneNumber(auth, number, recaptchaVerifier);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth,(currentUser) => {
      console.log("Auth", );
      setUser(currentUser);
    });
    return () => {
      unsubscribe();
    }
  },[]);

  const [state, dispatch] = useReducer(cartReducer, {
    Garments: labtestRef,
    cart: [],
  });

  return <userAuthContext.Provider value={{state, dispatch, user,signUp, logIn, logOut, googleSignIn, setUpRecaptcha }} >{children}</userAuthContext.Provider>
}
export function useUserAuth() {
  return useContext(userAuthContext);
}