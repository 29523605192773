
import './App.css';
import React from "react";
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';


import ProtectedRoute from "./components/pages/ProtectedRouter";

import UserAuthContextProvider from './contexts/UserAuthContext';
import Home from "./components/pages/Home";
import Footer from "./components/inc/Footer";

import Cicily from './components/pages/Cicily';

import DrDipayan from './components/pages/DrDipayan';
import Aboutus from './components/pages/Aboutus';
import Contactus from './components/pages/Contactus';
import Galleryhome from './components/pages/Galleryhome';
import Memberlist from './components/pages/Memberlist';
import Signup from './components/pages/Signup';
import Login from './components/pages/Login';
import UserDashboard from './components/pages/UserDashboard';
import Lifetime from './components/pages/Lifetime';
import Joinpage from './components/pages/Joinpage';
import Yearly from './components/pages/Yearly';
import Jacintha from './components/pages/Jacintha';

import OfficeB from './components/pages/OfficeB';
import Blood from './components/pages/Boold';
import ChessPhoto from './components/pages/ChessPhoto';
import SisterLalita from './components/pages/SisterLalita';
import Terms from './components/pages/Terms';
import Memories from './components/pages/Memories';
import Rakhi from './components/pages/Rakhi';
import Misc from './components/pages/Misc';
import Anthem from './components/pages/Anthem';
import Treeplant from './components/pages/Treeplant';
import CompleteEvent from './components/pages/CompleteEvent';




function App() {
  return (
    <Router>
       <UserAuthContextProvider>
     
    <div>
  
     
      
      <Routes>
 
     
      <Route axact path="/" element={<Home/>}/>
      {/* <Route axact path="/Sr.Elsyann" element={<Elsyann/>}/> */}
      <Route axact path="/Sr.Cicily" element={<Cicily/>}/>
      {/* <Route axact path="/Sr.Solly" element={<Solly/>}/> */}
      <Route axact path="/Sr.Jacintha" element={<Jacintha/>}/>
      {/* <Route axact path="/SMI_SisterSuperior" element={<Annie/>}/> */}
      <Route axact path="/DrDipayan_Tarafdar" element={<DrDipayan/>}/>
      <Route axact path="/about-us" element={<Aboutus/>}/>
      <Route axact path="/contact-us" element={<Contactus/>}/>
      <Route axact path="/Gallery" element={<Galleryhome/>}/>
      <Route axact path="/office-bearers" element={<OfficeB/>}/>
      <Route axact path="/Blood-donation" element={<Blood/>}/>
      <Route axact path="/SisterLalita" element={<SisterLalita/>}/>
      <Route axact path="/chess-material-donation" element={<ChessPhoto/>}/>
      <Route axact path="/Memberlist" element={<Memberlist/>}/>
      <Route axact path="/Memories" element={<Memories/>}/>
      {/* <Route axact path="/Newgallery" element={<Newgallery/>}/> */}
      <Route axact path="/Terms" element={<Terms/>}/>
      <Route axact path="/miscellaneous" element={<Misc/>}/>
      <Route axact path="/Anthem" element={<Anthem/>}/>
      <Route axact path="/Rakhi" element={<Rakhi/>}/>
v  <Route axact path="/CompleteEvent" element={<CompleteEvent/>}/>
      <Route axact path="/treeplantation-in-school-premises" element={<Treeplant/>}/>
      <Route axact path="/login" element={<Login/>} />


      <Route axact path="/signup" element={<Signup />} />
      {/* <Route path="/Photo/:docId" element={<DocNewg/>}/> */}
      <Route axact path="/Yearly_member" element={<ProtectedRoute> <Yearly/> </ProtectedRoute>} />
      <Route axact path="/Join_membership" element={<Joinpage/>} />
      <Route axact path="/Lifetime" element={<ProtectedRoute> <Lifetime/> </ProtectedRoute>} />
      <Route axact path="/UserDashboard/:userId" element={<ProtectedRoute> <UserDashboard /> </ProtectedRoute>} />
    </Routes>
    <Footer/>
  
    </div>
    </UserAuthContextProvider>
  
    </Router>
  );
}

export default App;
