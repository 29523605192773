import React from 'react'
import MISAA from "../images/main-school-slider.jpg";
import schoolbuilding from "../images/1.jpg";
import MISAABuilding from "../images/misaa building.jpg"

function Slider() {
  return (
    <div>
      <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={MISAABuilding} className="d-block w-100" alt="MISAA building"/>
      <div className="carousel-caption d-none d-md-block">
        <div className='backs'>
        <h4 className='mt-2'>Mary Immaculate School Alumni Association</h4>
        <p>Berhampore, Murshidabad</p>
        </div>
      </div>
    </div>
    <div className="carousel-item">
      <img src={MISAA} className="d-block w-100" alt="misaa"/>
      <div className="carousel-caption d-none d-md-block">
      <div className='backs'>
        <h4 className='mt-2'>Mary Immaculate School Alumni Association</h4>
        <p>Berhampore, Murshidabad</p>
        </div>
      </div>
    </div>
    <div className="carousel-item">
      <img src={schoolbuilding} className="d-block w-100" alt="Mary Immaculate School Alumni Association"/>
      <div className="carousel-caption d-none d-md-block">
      <div className='backs'>
        <h4 className='mt-2'>Mary Immaculate School Alumni Association</h4>
        <p>Berhampore, Murshidabad</p>
        </div>
      </div>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
 
    </div>
  )
}

export default Slider