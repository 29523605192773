
import React from 'react'
import misaaelection2024 from "../images/misaa election 2024.jpg"
import electionform from "../images/M ISAA Election 2024.pdf";
import misaaAnnual from "../images/annual programme misaa.jpeg"

function UpdateE() {
    return (
        <div>
            <div className="album py-3">
                <div className="container">
                    <div className="row">

                        <div className="col-md-4 mt-2">
                            <br/>
                            <br/>
                            <br/>
                 <a href='CompleteEvent'>
  <div className='btn-grad134'>EVENT COMPLETED</div>
  </a>
   </div>
                        <div className="col-md-8">
                        <div className='schoolberhampore'>
                <h2 className='blink mt-2' >M.I.S.A.A <b>Upcoming Events Bulletin</b> :</h2>
                <hr />
              </div>
                        <div className="card" >
                        <div class="card-header">        
                             <center> <h3 style={{ color: "red" }} className='blink'>New</h3> </center></div>
                             <img src={misaaAnnual} className="card-img-top mb-2" alt="misaaelection2024"/>
  <img src={misaaelection2024} className="card-img-top" alt="misaaelection2024"/>
  <div className="card-body">

    <a href={electionform} className="btn-grad90">MISAA ELECTION 2024 FORM DOWNLOAD</a>
  </div>
</div>

                        </div>
                    </div >
                </div >
            </div >
        </div >
    )
}

export default UpdateE
